// import FsLightbox from 'fslightbox';

export default class Gallery {
    constructor() {
        const galleries = document.querySelectorAll('.osprey-gallery');
        if (!galleries.length) {
            return;
        }

        new FsLightbox();

        // galleries.forEach(gallery => {
        // })
    }
}
