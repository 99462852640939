import '@styles/tailwind.css';
import '@styles/main.scss';
import TestimonialWidget from './widgets/testimonials-widget';
import Gallery from "@scripts/components/gallery";
import menu from "@scripts/components/menu";

document.addEventListener('DOMContentLoaded', () => {
    new TestimonialWidget();
    new Gallery();
    menu();
});
