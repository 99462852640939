export default function() {
    const hamburger = document.getElementById('hamburger');

    if (!hamburger) {
        return;
    }

    const menu = document.getElementById('mega-menu-primary_navigation'),
        overlay = document.getElementById('menu-overlay')

    const close = () => {
        menu?.classList.remove('is-active');
        hamburger.classList.remove('is-active');
        overlay?.classList.remove('is-active');
        document.documentElement.classList.remove('has-menu-open');
    }

    hamburger.addEventListener('click', ()=> {
        menu?.classList.toggle('is-active');
        hamburger.classList.toggle('is-active');
        overlay?.classList.toggle('is-active');
        document.documentElement.classList.toggle('has-menu-open');
    });

    overlay.addEventListener('click', () => close())
}
